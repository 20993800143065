<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>诗词</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/listPoem' }">诗词</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info margin-l-sm">

            <div class="boxContent" v-loading="boxLoading">



                <el-card class="box-card" style="width:800px;">
                    <el-descriptions :title="poetryDetails.title">
                        <el-descriptions-item label="作者">{{
                            basicsData.authorList?.find(v => v.id ==
                                poetryDetails.poem_author_id)?.name || ""
                        }}</el-descriptions-item>
                        <el-descriptions-item label="类型">{{
                            basicsData.typeList?.find(v => v.id ==
                                poetryDetails.poem_type_id)?.name || ""
                        }}</el-descriptions-item>
                        <el-descriptions-item label="朝代">{{
                            basicsData.dynastyList?.find(v => v.id ==
                                poetryDetails.poem_dynasty_id)?.name || ""
                        }}</el-descriptions-item>
                    </el-descriptions>
                    <div style="line-height: 22px;" v-html="poetryDetails.content"></div>
                </el-card>
                

              

                <el-card class="margin-t-sm  box-card" style="width:800px;">
                    <el-descriptions title="译文"></el-descriptions>
                    <div style="line-height: 22px;" v-html="poetryDetails.translation"></div>
                </el-card>

                <el-card class="margin-t-sm  box-card" style="width:800px;">
                    <el-descriptions title="鉴赏"></el-descriptions>
                    <div style="line-height: 22px;" v-html="poetryDetails.appreciate"></div>
                </el-card>

              

                <el-card class="margin-t-sm  box-card" style="width:800px;">
                    <el-descriptions title="注释"></el-descriptions>
                    <div style="line-height: 22px;" v-html="poetryDetails.note"></div>
                </el-card>

               

            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "template-details",

    data() {
        return {
            boxLoading: false,
            id: null,
            poetryDetails: {},
            basicsData: {},
        }
    },
    methods: {
        ...mapActions('listPoem', ['getPoem']),
        ...mapActions("common", ["getPoemBaseData"]),
        async getBasicsData() {
            this.boxLoading = true
            const { data } = await this.getPoemBaseData()
            this.basicsData = data
            const res = await this.getPoem(this.id)
            this.poetryDetails = res.data
            this.boxLoading = false
        },
    },
    mounted() {
        this.id = this.$route.params.id
        this.getBasicsData()
    },
}
</script>

<style scoped>
.margin-l-sm {
    margin-left: 1.25rem;
}

.boxContent {
    padding: 40px;
    box-shadow: 0 12px 32px rgba(0, 0, 0, .1), 0 2px 6px rgba(0, 0, 0, .08);
    border-radius: 8px;
}

.margin-t-sm {
    margin-top: 10px;
}

.content {
    margin-top: 10px;
    box-shadow: 0 12px 32px rgba(0, 0, 0, .1), 0 2px 6px rgba(0, 0, 0, .08);
    padding: 10px;
    width: 800px;
}
</style>
